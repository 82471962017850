
import { FaCheckDouble, FaHome, FaHouseUser, FaMoneyBillAlt, FaPeopleArrows, FaUsers, FaUserFriends, FaTasks, FaRegBuilding, FaCity, FaStreetView, FaMoneyCheckAlt} from "react-icons/fa";
import { Link } from "react-router-dom";
import './style.css';

const logo = require('../../assets/logo.png');

const CmsMenu = () => {

    return (
        <nav className="navbar navbar-expand-lg">
            <div className="container imgFondoCut justify-content-start" >
                {/* Imagén marca fluida */}
                <div style={{width: "40%"}}>
                    <img src={logo} alt="" className="img-fluid" style={{width: "70%"}}/>
                </div>
                {/* Ícono de hamburguesa en modo responsive*/}
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        {/* Home*/} 
                        <li className="nav-item dropdown  me-3 ">
                            <Link to="/cms/creaInmueble" className={`nav-link  text-decoration-none me-2 d-flex flex-row align-items-center gap-1 `} id="navbarDropdownDashboard" role="button"  aria-expanded="false" key={0}> 
                                <FaHome /> Inmuebles
                            </Link>                          
                        </li>

                        {/* Menú contacto*/} 
                        <li className="nav-item dropdown  me-3 ">
                            <Link to="/cms/creaPropietario" className={`nav-link text-decoration-none me-2 d-flex flex-row align-items-center gap-1 `}  id="navbarDropdownInventario" role="button" aria-expanded="false" key={0}> 
                                <FaPeopleArrows /> Propietarios
                            </Link>                           
                        </li>

                        {/* Menú nosotros*/} 
                        <li className="nav-item dropdown  me-3 ">
                            <Link to="/cms/creaCaracteristica" className={`nav-link text-decoration-none me-2 d-flex flex-row align-items-center gap-1 `}  id="navbarCmsCaracteristicas"  role="button" aria-expanded="false" key={0}> 
                                <FaMoneyCheckAlt /> Ventas
                            </Link>                           
                        </li>

                        {/* Menú servicios*/} 
                        <li className="nav-item dropdown  me-3 ">
                            <Link to="/" className={`nav-link dropdown-toggle text-decoration-none me-2 d-flex flex-row align-items-center gap-1 `}  id="navbarDropdownReferencia" role="button" data-bs-toggle="dropdown" aria-expanded="false" key={0}> 
                                <FaCheckDouble />Parámetros
                            </Link>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownReferencia">                               
                        {/*                                 <li><Link className={`dropdown-item `} to="/" ><FaShopify /> Compras</Link></li> */}
                                <li><Link className={`dropdown-item `} to="/cms/creaCiudad" ><FaCity /> Ciudades</Link></li>
                                <li><Link className={`dropdown-item `} to="/cms/creaCiudad" ><FaStreetView /> Barrios</Link></li>
                                <li><Link className={`dropdown-item `} to="/cms/creaTipoInmueble" ><FaRegBuilding /> Tipo Inmuebles</Link></li>
                                <li><Link className={`dropdown-item `} to="/cms/creaCaracteristica" ><FaTasks /> Características</Link></li>
                                <li><Link className={`dropdown-item `} to="/" ><FaUserFriends /> Vendedores</Link></li>
                                <li><Link className={`dropdown-item `} to="/" ><FaUsers /> Usuarios</Link></li>
                        {/*                                 <li><Link className={`dropdown-item `} to="/" ><FaLandmark /> Hipotecas</Link></li>
                                <li><Link className={`dropdown-item `} to="/" ><FaDollarSign /> Avalúos</Link></li>   */}                                                             
                            </ul>    
                        </li>

                    </ul>
                </div>
            </div>
        </nav>        
    );
};

export default CmsMenu;