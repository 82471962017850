import { useState } from "react";
import { Button } from "react-bootstrap";
import "./style.css";

const CardCarrousel = (props: any) =>{

    const [masdetalles, setMasDetalles] = useState(true);

    return (
            <div className="card border-0 w-100"  key={props.idx} >
                {/*****************************************
                 * Carrusel de imagenes
                 ******************************************/}
                <div id={`Carrousel-${props.idx}`} className="carousel slide">
                    <div className="carousel-inner">              
                        {
                            props.data.fotos.map((img: any, idx: number)=>
                                <div className={`carousel-item ${(idx < 1) ? "active" : ""}`}>
                                    <div key={idx}  className="d-flex flex-row justify-content-center">
                                        <img src={img.pathPhoto} className="d-block w-100 " alt="..."/>                            
                                    </div>
                                </div>    
                            )
                        }
                    </div>

                    <button className="carousel-control-prev " type="button" data-bs-target={`#Carrousel-${props.idx}`} data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target={`#Carrousel-${props.idx}`} data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>  
                {/*****************************************
                 * Listado de caracteristicas del inmueble
                 ******************************************/}
                <div id="texto" className="card-body d-flex flex-column gap-2">

                    <div className=" d-flex flex-row gap-2">
                        <div className="w-75 bg-light "><label>Año Construcción</label></div>
                        <div className="w-25 bg-light "><label>{props.data.anoConstruccion}</label></div>
                    </div>

                    <div className=" d-flex flex-row gap-2">
                        <div className="w-75 bg-light "><label>Área total</label></div>
                        <div className="w-25 bg-light "><label>{props.data.areaTotal}</label></div>
                    </div>

                    <div className=" d-flex flex-row gap-2">
                        <div className="w-75 bg-light "><label>Área construida</label></div>
                        <div className="w-25 bg-light "><label>{props.data.areaConstruida}</label></div>
                    </div>

                    <div className=" d-flex flex-row gap-2">
                        <div className="w-75 bg-light "><label>Habitaciones</label></div>
                        <div className="w-25 bg-light "><label>{props.data.nroAlcobas}</label></div>
                    </div>      

                    <div className=" d-flex flex-row gap-2">
                        <div className="w-75 bg-light "><label>Baños</label></div>
                        <div className="w-25 bg-light "><label>{props.data.nroBanos}</label></div>
                    </div>     

                    <div className=" d-flex flex-row gap-2">
                        <div className="w-75 bg-light "><label>Garajes</label></div>
                        <div className="w-25 bg-light "><label>{props.data.nroGarajes}</label></div>
                    </div>    

                        <div className={`d-flex flex-row gap-2 ${masdetalles ? 'd-none': ''}`} >
                            <div className="w-25 bg-light "><label>Descripción</label></div>
                            <div className="w-75 bg-light "><label>{props.data.descripcion}</label></div>
                        </div>   
                        {
                            props.data.caracteristicas.map((car: any, idx: number)=>{
                                return(
                                    <div className={`d-flex flex-row gap-2 ${masdetalles ? 'd-none': ''}`}>
                                        <div className="w-100 bg-light " key={idx}><label>{car.nombre}</label></div>
{/*                                         <div className="w-25 bg-light "><label>1</label></div>   */}                                          
                                    </div>
                                )
                            })
                        }
                    <div>
                        <Button className="border-0" onClick={()=>setMasDetalles(!masdetalles)} style={{backgroundColor: "#574B94"}}>Más detalles</Button>
                    </div>                     
                </div>
            </div>        
    );
};

export default CardCarrousel;