
import { FaCheckDouble,  FaHome, FaHouseUser, FaMoneyBillAlt, FaRegEnvelopeOpen, FaUsers} from "react-icons/fa";
import { Link } from "react-router-dom";
import './style.css';

const logo = require('../../assets/logo.png');

const BarraMenu = () => {

    return (
        <nav className="navbar navbar-expand-lg">
            <div className="container imgFondoCut justify-content-start" >
                {/* Imagén marca fluida */}
                <div style={{width: "40%"}}>
                    <img src={logo} alt="" className="img-fluid" style={{width: "70%"}}/>
                </div>
                {/* Ícono de hamburguesa en modo responsive*/}
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        {/* Home*/} 
                        <li className="nav-item dropdown  me-3 ">
                            <Link to="/" className={`nav-link dropdown-toggle text-decoration-none me-2 d-flex flex-row align-items-center gap-1 `} id="navbarDropdownDashboard" role="button" data-bs-toggle="dropdown" aria-expanded="false" key={0}> 
                                <FaHome /> Home
                            </Link>  
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownReferencia">                               
                                <li><Link className={`dropdown-item `} to="/" ><FaHome /> Home</Link></li>                                                           
                            </ul>                             
                        </li>

                        {/* Menú servicios*/} 
                        <li className="nav-item dropdown  me-3 ">
                            <Link to="/" className={`nav-link dropdown-toggle text-decoration-none me-2 d-flex flex-row align-items-center gap-1 `}  id="navbarDropdownReferencia" role="button" data-bs-toggle="dropdown" aria-expanded="false" key={0}> 
                                <FaCheckDouble />Servicios
                            </Link>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownReferencia">                               
{/*                                 <li><Link className={`dropdown-item `} to="/" ><FaShopify /> Compras</Link></li> */}
                                <li><Link className={`dropdown-item `} to="/arriendos" ><FaMoneyBillAlt /> Ventas</Link></li>
                                {/* <li><Link className={`dropdown-item `} to="/arriendos" ><FaHouseUser /> Arriendos</Link></li>                                                     */}
                            </ul>    
                        </li>

                        {/* Menú contacto*/} 
                        <li className="nav-item dropdown  me-3 ">
                            <Link to="/" className={`nav-link dropdown-toggle text-decoration-none me-2 d-flex flex-row align-items-center gap-1 `}  id="navbarDropdownInventario" role="button" data-bs-toggle="dropdown" aria-expanded="false" key={0}> 
                                <FaRegEnvelopeOpen /> Contacto
                            </Link>  
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownReferencia">                               
                                <li><Link className={`dropdown-item `} to="/contactenos" ><FaRegEnvelopeOpen /> Contactenos</Link></li>                                                           
                            </ul>                                
                        </li>

                        {/* Menú nosotros*/} 
                        <li className="nav-item dropdown  me-3 ">
                            <Link to="/" className={`nav-link dropdown-toggle text-decoration-none me-2 d-flex flex-row align-items-center gap-1 `}  id="navbarDropdownConsultas" role="button" data-bs-toggle="dropdown" aria-expanded="false" key={0}> 
                                <FaUsers /> Nosotros
                            </Link> 
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownReferencia">                               
                                <li><Link className={`dropdown-item `} to="/nosotros" ><FaUsers /> ¿Quienes somos?</Link></li>                                                           
                            </ul>                                
                        </li>

                    </ul>
                </div>
            </div>
        </nav>        
    );
};

export default BarraMenu;