import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PageLandingPage from './pages/LandingPage';
import QuienesSomosPage from './pages/QuienesSomosPage';
import ContactenosPage from './pages/ContactoPage';
import ServicioArriendoPage from './pages/ServicioArriendoPage';
import CmsInmueblePage from './pages/CmsInmueblePage';
import CmsPropietarioPage from './pages/CmsPropietarioPage';
import CmsMantenimientoPage from './pages/CmsMantenimientoPage';
import CmsCiudadPage from './pages/CmsCiudadPage';
import CmsCaracteristicaPage from './pages/CmsCaracteristicaPage';
import CmsTipoInmueblePage from './pages/CmsTipoInmueblePage';

function App() {
  return (
    <div>
      <BrowserRouter>      
        <Routes>
          <Route path="/" element={<PageLandingPage />}/>     
          <Route path="/nosotros" element={<QuienesSomosPage />}/>   
          <Route path="/contactenos" element={<ContactenosPage />}/> 
          <Route path="/arriendos" element={<ServicioArriendoPage />}/>      
          <Route path="/cms/creaInmueble" element={<CmsInmueblePage />}/>          
          <Route path="/cms/creaPropietario" element={<CmsPropietarioPage />}/>             
          <Route path="/cms/creaCiudad" element={<CmsCiudadPage />}/>                
          <Route path="/cms/creaCaracteristica" element={<CmsCaracteristicaPage />}/>       
          <Route path="/cms/creaTipoInmueble" element={<CmsTipoInmueblePage />}/>   
          <Route path="/cms/mantenimiento" element={<CmsMantenimientoPage />}/>                                                        
        </Routes>              
      </BrowserRouter>      
    </div>
  );
}

export default App;
