import BarraFooter from '../../components/BarraFooter';
import BarraMenu from '../../components/BarraMenu';
import ContactosComentarios from '../../components/ContactosComentarios';
import './style.css'

const banner = require('../../assets/bannerpaginaprincipal.png');

const PageLandingPage = () => {

    return(
        <div className='container'>
            <div className='text-center '>
                <BarraMenu /> 
            </div>

            <div className=" img-fluid  " style={{boxShadow: "1px 2px 5px"}}>
                <img src={banner} alt="" height={365}  className="img-fluid "/>
            </div>            

            <ContactosComentarios />

            <div className='text-center'>
                <BarraFooter />
            </div>             
        </div>
    )

};

export default PageLandingPage;