import { useEffect, useState } from 'react';
import BarraFooter from '../../components/BarraFooter';
import BarraMenu from '../../components/BarraMenu';
import './style.css'
import CardCarrousel from './Card';
import ContactosComentarios from '../../components/ContactosComentarios';
import { httpApiGet } from '../../lib';

const banner = require('../../assets/fotospaginas_Mesadetrabajo1.png');
const bgImg = require("../../assets/botonlimpiar.png");

const ServicioArriendoPage = () => {   

    const [tipoInmueble, setTipoInmueble] = useState([]);
    const [tipoPropiedades, setTipoPropiedades] = useState([]); 
    const [caracteristicas, setCaracteristicas] = useState([]); 
    let [listaInmuebles, setlistaInmuebles] = useState([]); 

    useEffect(()=>{

        const inicilizacion = async () => {
            
            let resp;

            //Trae los tipos de propiedades
            resp = await httpApiGet("/tipoPropiedad");
            setTipoPropiedades(resp.message);          

            //Trae los estados del inmueble
            resp = await httpApiGet("/tipoInmueble");
            setTipoInmueble(resp.message); 
            
            //Trae los caracteristicas        
            resp = await httpApiGet("/caracteristica");
            setCaracteristicas(resp.message);  

            //Trae todos los inmuebles        
            resp = await httpApiGet("/inmueble/full");
            listaInmuebles = resp.message;
            setlistaInmuebles(resp.message);     
            console.log(listaInmuebles);      
             
        }

        inicilizacion();

    }, []);

    const Interno = (props: any) =>{

      const [listArr, setListArr] = useState([...props.data]);
 
      return (
        <div  className=' mt-1 d-flex flex-row' >
            {/****************************
             * formulario
            *****************************/}
            <div className='rounded divForm me-2 ' style={{width: "30%", height:"100%", boxShadow: "1px 2px 5px"}}>
                <form className="row g-1 p-4 " >
                    <h4>Filtro de inmuebles</h4>
                    <div className="col-lg-12">
                        <label htmlFor="tp" className="form-label">Tipo de propiedad</label>
                        <select className="form-select form-select-sm " aria-label="Small select example" defaultValue="0" id="tp" >
                        <option value="0">Seleccione una opción</option>
                        {
                            tipoInmueble.map((data: any, idx: number) => <option key={idx} value={data.idTipoInmueble}>{data.tipoInmueble}</option>)
                        }
                        </select>
                    </div>
                    <div className="col-lg-12">
                        <label htmlFor="ep" className="form-label">Estado del inmueble</label>
                        <select className="form-select form-select-sm" aria-label="Small select example" defaultValue="0" id="ep">
                        <option value="0">Seleccione una opción</option>
                        {
                            tipoPropiedades.map((data: any, idx: number) => <option key={idx} value={data.idTipoPropiedad}>{data.tipo}</option>)
                        }
                        </select>
                    </div> 

                    <div className="col-lg-6">
                        <label htmlFor="precio-d" className="form-label">Precio </label>
                        <input type="text" className="form-control " id="precio-d" value=""  placeholder="De" />
                    </div>
                    <div className="col-lg-6">
                        <label htmlFor="precio-a" className="form-label">A</label>
                        <div className="input-group has-validation">
                            <input type="text" className="form-control" value="" id="precio-a" aria-describedby="inputGroupPrepend"  placeholder=""  required />
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <label htmlFor="ciudad" className="form-label">Ciudad</label>
                        <div className="input-group has-validation">
                            <input type="text" className="form-control" value=""  id="ciudad" aria-describedby="inputGroupPrepend"  placeholder="ciudad"  required />
                        </div>
                    </div>                  
                    <div className="col-lg-12">
                        <label htmlFor="barrio" className="form-label">Barrio</label>
                        <div className="input-group has-validation">
                            <input type="text" className="form-control" value=""  id="barrio" aria-describedby="inputGroupPrepend"  placeholder="barrio"  required />
                        </div>
                    </div>  

                    <div className="col-lg-6">
                        <label htmlFor="hab-d" className="form-label">Habitaciones </label>
                        <input type="text" className="form-control" id="hab-d" value=""  placeholder="De"   />
                    </div>
                    <div className="col-lg-6">
                        <label htmlFor="hab-a" className="form-label">A</label>
                        <div className="input-group has-validation">
                            <input type="text" className="form-control" value=""  id="hab-a" aria-describedby="inputGroupPrepend"  placeholder=""  required />
                        </div>
                    </div>    

                    <div className="col-lg-6">
                        <label htmlFor="banos-d" className="form-label">Baños </label>
                        <input type="text" className="form-control" id="banos-d" value=""  placeholder="De"   />
                    </div>
                    <div className="col-lg-6">
                        <label htmlFor="banos-a" className="form-label">A</label>
                        <div className="input-group has-validation">
                            <input type="text" className="form-control" value=""   id="banos-a" aria-describedby="inputGroupPrepend"  placeholder=""  required />
                        </div>
                    </div>     

                    <div className="col-lg-6">
                        <label htmlFor="garajes-d" className="form-label">Garajes </label>
                        <input type="text" className="form-control" id="garajes-d" value=""  placeholder="De"   />
                    </div>
                    <div className="col-lg-6">
                        <label htmlFor="garajes-a" className="form-label">A</label>
                        <div className="input-group has-validation">
                            <input type="text" className="form-control" value=""   id="garajes-a" aria-describedby="inputGroupPrepend"  placeholder=""  required />
                        </div>
                    </div>                                                   

                    <div className="col-lg-6">
                        <label htmlFor="anoconst-d" className="form-label">Año de cons. </label>
                        <input type="text" className="form-control" id="anoconst-d" value=""  placeholder="De"   />
                    </div>
                    <div className="col-lg-6">
                        <label htmlFor="anosconst-a" className="form-label">A</label>
                        <div className="input-group has-validation">
                            <input type="text" className="form-control" value=""   id="anosconst-a" aria-describedby="inputGroupPrepend"  placeholder=""  required />
                        </div>
                    </div>

                    <div className="col-lg-12 m-2">
                        <label htmlFor="validationCustom02" className="form-label fw-bold">CARACTERISTICAS</label>
                    </div>  

                    <div className="col-lg-12 m-2">   
                    {
                        caracteristicas.map((data: any, key: number) => {
                            return (
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" role="switch" id={"car-" + data.idCaracteristica} 
                                        name={data.idCaracteristica} 
                                    />
                                    <label className="form-check-label" htmlFor={"car-" + data.idCaracteristica}>{data.nombre}</label>                                                                             
                                </div>
                            )
                        })
                    }                      
                    </div>               

                    <div className="col-auto mt-3 "  style={{margin: "auto" }}>
                        <input type="button" className="border-0 rounded" style={{width: "150px", height: "42px", backgroundImage: `url(${bgImg})`, backgroundSize: "cover" }}/>
                    </div> 
                </form>
            </div>

            {/****************************
             * Crrusel de imagenes
            *****************************/}
            <div  style={{width: "70%", height:"1050px", boxShadow: "1px 2px 5px", overflowX: "hidden", overflowY:"scroll"}} className=' rounded ms-2'>

                {
                    listArr.map((dt: any, idx: number)=>
                        <div className='mb-3 border shadows'>
                            <CardCarrousel key={idx} data={dt} idx={idx}/>
                        </div>
                    )
                }       

            </div>
        </div>            
      );
    }

    return(
        <div className='container'>
            {/*******************
             * Header - only MenuBar
            *********************/}              
            <div className='text-center '>
                <BarraMenu /> 
            </div>
            {/*******************
             * Body
            *********************/}  
            <div className='' style={{border: "10px", borderColor: "red"}}>
                <div className="" style={{boxShadow: "1px 2px 5px"}}>
                    <img src={banner} alt="" height={365}  className="img-fluid "/>
                </div>

                <div className=' d-flex justify-content-center mt-1 '>

                    <Interno data={listaInmuebles}/>

                </div>

                <ContactosComentarios />

            </div>

            <div style={{height: "40px"}} className='imgFondoCut'></div>   
            {/*******************
             * Footer
            *********************/}         
            <div className='text-center'>
                <BarraFooter />
            </div>             
        </div>
    )

};

export default ServicioArriendoPage;