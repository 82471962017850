import BarraFooter from '../../components/BarraFooter';
import BarraMenu from '../../components/BarraMenu';
import ContactosComentarios from '../../components/ContactosComentarios';
import './style.css'

const banner = require('../../assets/fotospaginas_Mesadetrabajo1copia.png');
const bgImg = require("../../assets/botonenviar2.png");

const ContactenosPage = () => {

    const icoEm = require("../../assets/iconoscontactenos_Mesadetrabajo1copia2.png");    
    const icoTs = require("../../assets/iconoscontactenos_Mesadetrabajo1copia.png");   
    const icoGs = require("../../assets/iconoscontactenos_Mesadetrabajo1.png");       

    const Interno = () =>{
 
      return (
            <div  className='rounded shadow bg-light p-4 bodyInterno'>

                <div className=' iconosContacto'>

                    <div className='iconSize ' >
                        <div className='' style={{width: "80px", margin: "auto"}} >
                            <img src={icoGs} alt="" className='img-fluid'/>
                        </div>
                        <div  className=' text-center'>
                            <label htmlFor="">Escallon Villa CL 30F No 55-25</label>
                        </div>                     
                    </div>

                    <div className='iconSize ' >
                        <div className=''  style={{width: "80px",  margin: "auto"}} >
                            <img src={icoTs} alt="" className='img-fluid'/>
                        </div>
                        <div className=' text-center'>
                            <label htmlFor="">324 562 7445</label>
                        </div>                      
                    </div>

                    <div className='iconSize ' >
                        <div className=''  style={{width: "50px", margin: "auto"}} >
                            <img src={icoEm} alt="" className='img-fluid'/>
                        </div>
                        <div className='text-center'>
                            <label htmlFor="">contacto@romarba.com</label>
                        </div>                      
                    </div> 

                </div>

                <div style={{border: "solid 1px ", width: "100%", margin: "5px 0px 5px 0px"}}></div>

                <div className=' ms-2 me-2 mt-3'>

                    <form className="row " >
                        <div className="col-lg-12 text-center h5">
                            <label className="form-label fw-bolder">CONTÁCTENOS</label>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <label htmlFor="validationCustom01" className="form-label">Nombre</label>
                            <input type="text" className="form-control" id="validationCustom01" value=""  placeholder="Nombre completo" />
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <label htmlFor="validationCustom02" className="form-label">Email</label>
                            <input type="text" className="form-control" id="validationCustom02" value=""  placeholder="miCorreo@ejemplo.com"   />
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <label htmlFor="validationCustomUsername" className="form-label">Teléfono</label>
                            <div className="input-group has-validation">
                                <input type="text" className="form-control" id="validationCustomUsername" aria-describedby="inputGroupPrepend"  placeholder="###-####-###"  required />
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12" >
                            <label htmlFor="exampleFormControlTextarea1" className="form-label">Mensaje para nosotros</label>
                            <textarea className="form-control  shadow-sm" id="exampleFormControlTextarea1" rows={5}></textarea>
                        </div>                    

                        <div className=" mt-3 mb-3 d-flex flex-row justify-content-center text-center " >
                            <button type="button" className="border-0 text-light fw-bold" style={{width: "150px", height: "42px", backgroundImage: `url(${bgImg})`, backgroundSize: "cover" }}>ENVIAR</button>
                        </div> 
                    </form>                          

                </div>
            </div>    
      );
    }

    return(
        <div className='container '>
            <div className='text-center '>
                <BarraMenu /> 
            </div>

            <div style={{boxShadow: "1px 2px 5px"}}>
                <img src={banner} alt="" height={365}  className="img-fluid "/>
            </div>
            
            <div className=' d-flex justify-content-center position-relative divContent'>
                <Interno />       
            </div> 
            <div className='divContent'></div>

            <div className=''>
                <BarraFooter />                   
            </div> 
             
        </div>
    )
};

export default ContactenosPage;