import './style.css'

const bgImgButton = require("../../assets/botonenviar.png");
const bgImgCel = require("../../assets/datoscontactofooter-cel.png");
const bgImgEmail = require("../../assets/datoscontactofooter-email.png");
const bgImgGps = require("../../assets/datoscontactofooter-gps.png");
const bgImgHorario = require("../../assets/datoscontactofooter-horario.png");
const bgImgRedes= require("../../assets/datoscontactofooter-redesSociales.png");

const ContactosComentarios = () => {

    return(
            <div>

                <div className='' style={{height: "40px"}}></div>

                <div className="bdyQuery">

                    <div className="divContacto imgBarFondo text-center d-flex justify-content-center">

                        <div className='divContactosInternos'>

                            <div style={{height: "125px"}} ></div> 

                            <div className='divDatosContactosCel' style={{backgroundImage: `url(${bgImgCel})`, backgroundSize: "contain",  backgroundRepeat:"no-repeat" }}>324 562 7445</div>

                            <div className='divDatosContactosDemas'  style={{backgroundImage: `url(${bgImgEmail})`, backgroundSize: "contain",  backgroundRepeat:"no-repeat" }}>contacto@romarba.com</div>

                            <div className='divDatosContactosDemas'   style={{backgroundImage: `url(${bgImgGps})`, backgroundSize: "contain",  backgroundRepeat:"no-repeat" }}>Escallon Villa CL 30F No 55-25</div>  
                        
                            <div className='divDatosContactosDemas'   style={{backgroundImage: `url(${bgImgHorario})`, backgroundSize: "contain",  backgroundRepeat:"no-repeat" }}>Lunes a Sábado / 8:00 AM - 6:00 PM</div>       

                            <div className='divDatosContactosDemas'   style={{backgroundImage: `url(${bgImgRedes})`, backgroundSize: "contain",  backgroundRepeat:"no-repeat" }}><pre></pre></div>      

                        </div>

                    </div>

                    <div  className=" imgFondoBdy divComentarios" style={{height: "365px"}} >
                        <form className='row '>
                            <div className="mb-1">
                                <label htmlFor="exampleFormControlTextarea1" className="form-label h6">Comentanos, queremos tu opinión!</label>
                            </div>
                            <div className=" col-md-12 col-lg-7 mb-1 " >
                                <label htmlFor="exampleFormControlInput1" className="form-label">Correo</label>
                                <input type="email" className="form-control shadow-sm" id="exampleFormControlInput1" placeholder="name@example.com"/>
                            </div>
                            <div className="col-md-12 col-lg-7 mb-1 " >
                                <label htmlFor="exampleFormControlTextarea1" className="form-label">Mensaje para nosotros</label>
                                <textarea className="form-control  shadow-sm" id="exampleFormControlTextarea1" rows={3}></textarea>
                            </div>
                            <div className="col-md-12  col-lg-6 mt-3 text-center" >
                                <button type="button" className="border-0" style={{width: "150px", height: "42px", backgroundImage: `url(${bgImgButton})`, backgroundSize: "cover" }}></button>
                            </div> 
                        </form>                                                   
                    </div>
                    
                </div>

                <div className='imgFondoCut' style={{height: "40px"}} ></div>                   
                
            </div>
    )

};

export default ContactosComentarios;