import BarraFooter from '../../components/BarraFooter';
import BarraMenu from '../../components/BarraMenu';
import ContactosComentarios from '../../components/ContactosComentarios';
import './style.css'

const banner = require('../../assets/fotospaginas_Mesadetrabajo1copia2.png');

const QuienesSomosPage = () => {

    const icoQS = require("../../assets/iconosnosotros_Mesadetrabajo1copia.png");    
    const icoMs = require("../../assets/iconosnosotros_Mesa de trabajo1.png");   
    const icoVs = require("../../assets/iconosnosotros_Mesa de trabajo1copia2.png");       

    const Interno = () =>{
 
      return (
        <div  className=' rounded shadow bg-light p-4 bodyInterno ' >

            <div className=' p-2' >

                <div className='p-1  text-center'>
                    <img src={icoQS} alt="" width={150} className='img-fluid'/>
                </div>  
                <div className='p-3  textAlign '>
                    <p>Agencia inmobiliaria integral comprometida con brindar la mejor asesoría y acompañamiento en la inversión del patrimonio inmobiliario de nuestros clientes.</p>
                </div>              

            </div>

            <div className='p-2 iconosContacto' >
                <div className='p-1  '>
                    <div className=' text-center' >
                        <img src={icoMs} alt="" width={80}  className='img-fluid'/>
                    </div>
                    <div className='p-3  textAlign '>
                        <p>Cuidar el patrimonio inmobiliario de nuestros clientes, basado en la amplia experiencia de nuestros colaboradores, a partir del acompañamiento permanente.</p>
                    </div>                     
                </div>
                <div className='p-1 '>
                    <div className=' text-center'  >
                        <img src={icoVs} alt="" width={70}   className='img-fluid'/>
                    </div>
                    <div className='p-3  textAlign '>
                        <p >Para 2028 seremos una compañía reconocida en la ciudad Cartagena por generar confianza a nuestros clientes, y que estos nos consideren su aliado estratégico para desarrollar sus inversiones inmobiliarias.</p>
                    </div>                      
                </div>
            </div>

        </div>            
      );
    }


    return(
        <div className='container '>
            <div className='text-center '>
                <BarraMenu /> 
            </div>

            <div style={{boxShadow: "1px 2px 5px"}}>
                <img src={banner} alt="" height={365}  className="img-fluid "/>
            </div>

            <div className=' d-flex justify-content-center position-relative divContent'>
                <Interno />       
            </div>

            <div className='divContent2'>
             
            </div>

            <div className='registro ' style={{marginTop: "250px"}}>
                <ContactosComentarios />                   
            </div>


            <BarraFooter />
             
        </div>
    )

};

export default QuienesSomosPage;